import rulesImg from '../../../images/rules.jpg'

export const rules = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Playgroup Game Rules & Absence Policy',
  description: [
    "Place two shuttles at the start with the coordinator of the day.",
    "As long as you continue to win, you keep playing. However, to ensure fair mix, split the players and play with a new team member.",
    "Winners continue playing but split up to ensure fair rotations.",
    "Challengers move off court to the back of the queue with the goal of joining a court they did not come off, where possible.",
    "Line judgement is made by the player on court that is closest and has the best view of the shuttle.",
    "Reasonable (>24hrs) notice of absence is required to allow coordinators time to recruit drop-in players.",
    "We will try but cannot guarantee fee adjustments for long-term absences through replacement players.",
    "Inform us in advance if planning a vacation for replacements."
  ],
  imgStart: '',
  img: rulesImg,
  alt: ''
};