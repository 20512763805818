import React from 'react';
import HeroSection from '../../HeroSection';
import { membership, payment } from './Data';

const Membership = () => {
  return (
    <>
      <HeroSection {...membership} />
      <HeroSection {...payment} />
    </>
  )
}

export default Membership
