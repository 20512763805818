import './App.css';
import NavBar from './components/NavBar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/pages/Footer/Footer';
import Home from './components/pages/HomePage/Home';
import Membership from './components/pages/Memberships/Membership';
import Rules from './components/pages/GameRules/Rules';
import PlaySession from './components/pages/PlaySessions/PlaySession';
// import Sessions from './components/pages/PlaySessions/Sessions';


const App = () => {
  return (
    <BrowserRouter>
      <NavBar/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/memberships' element={<Membership/>} />
        <Route path='/game-rules' element={<Rules/>} />
        <Route path='/play-sessions' element={<PlaySession/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
