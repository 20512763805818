import React from 'react';
import HeroSection from '../../HeroSection';
import { rules } from './Data';

const Rules = () => {
  return (
    <>
      <HeroSection {...rules} />
    </>
  )
}

export default Rules
