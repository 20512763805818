import React, { useState, useEffect } from 'react';
import './PlaySession.css';

const PlaySession = () => {
  // const [playSessions, setPlaySessions] = useState([]);
  const [playSessions, setPlaySessions] = useState(() => {
    // Retrieve data from localStorage on component mount
    const storedSessions = localStorage.getItem('sessions');
    return storedSessions ? JSON.parse(storedSessions) : null;
});


  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetch('/playSessionsData.json'); // Fetch local data
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setPlaySessions(jsonData.playSessions);

            // Store data in localStorage
            localStorage.setItem('sessions', JSON.stringify(jsonData.playSessions));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    fetchData();
  }, []);

  // Check if sessions is null or undefined before accessing its properties
  if (!playSessions) {
    return <p>Loading...</p>;
  }


  return (
    <>
      <div className="play-container">
        <div className="play-row">
          <div className="col-span-all center-text"><h1 className='col-header'>{playSessions.topLine}</h1></div>
        </div>
        <div className="play-row">
          <div className="play-col">
          {
            playSessions.seasons.map((season,seasonIndex) => 
              <div key={`snr_${seasonIndex}`} className="play-row">
                <div key={`snc_${seasonIndex}`} className="play-col">
                  <h2 key={`tbh_${seasonIndex}`} className='table-title'>{season.season} {season.year} {season.months}</h2>
                    <div key={`tbres_${seasonIndex}`} className="table-responsive">
                      <table key={`tb_${seasonIndex}`} className="table">
                          <thead>
                            <tr>
                              {
                                season.tableHeaders.map((tableHeader,index) => 
                                  <th key={index}>{tableHeader}</th> 
                                )
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              season.tablerows.map((tableRow,rowIndex) => 
                                <tr key={`row_${rowIndex}`}>
                                  {
                                    tableRow.row.map((colVal,colIndex) => 
                                      <td key={colIndex}>{colVal}</td>
                                    )
                                  }
                                </tr>
                              )
                            }
                    
                          </tbody>
                        </table>
                      </div>
                  </div>
                </div>
                )
              }
          </div>
          <div className="play-col">
            {/* <img src={require(`../../../images/${playSessions.img}`)}  alt = {playSessions.topLine}/> */}
            <img src={playSessions.img} alt = {playSessions.topLine}/>
          </div>
        </div>
      </div>
    </>
  )
}

export default PlaySession
