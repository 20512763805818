import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import shuttleLogo from '../../../images/ShuttleLogo.jpg';

const Footer = () => {
    return (
        <div className='footer-container'>
          <section className='footer-subscription'>
            <p className='footer-subscription-heading'>
              We play at Batts Athletics - 1233 Dillon Road, Burlington, ON L7M 1K6
            </p>
            <p className='footer-subscription-text'>
              On Google Maps: <a href="https://www.google.com/search?q=1233+Dillon+Rd%2C+Burlington%2C+ON+L7M+1K6&rlz=1C1BFEM_enCA1079CA1079&oq=1233+Dillon+Rd%2C+Burlington%2C+ON+L7M+1K6&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQIRigATIHCAIQIRiPAjIHCAMQIRiPAtIBCDEwMjNqMGo0qAIAsAIA&sourceid=chrome&ie=UTF-8" target="_blank" rel="noreferrer">
                        Batts Athletics Burlington
                      </a>
            </p>
            <p className='footer-subscription-text'>
              For Racket Stringing: <a href="mailto:string@badmintonplaygroup.com?subject=String%20My%20Racket%20Please" rel="noreferrer">
                        String my racket
                      </a>
            </p>
          </section>
          <section className='footer-subscription'>
            <p className='footer-subscription-heading'>
              Tuesdays: 7PM - 9PM, Thursdays: 7PM - 9PM
            </p>
            <p className='footer-subscription-text'>
            Contact: Atul, Email: info@badmintonplaygroup.com
            </p>
          </section>
          <section className='social-media'>
            <div className='social-media-wrap'>
              <div className='footer-logo'>
                <Link to='/' className='social-logo'>
                <img src={shuttleLogo} className="footer-icon" alt="logo" />
                  Badminton Playgroup
                </Link>
              </div>
              <small className='website-rights'>Badminton Playgroup © 2024</small>
              <div className='social-icons'>
                <Link
                  className='social-icon-link'
                  to='/'
                  target='_blank'
                  aria-label='Facebook'
                >
                  <FaFacebook />
                </Link>
                <Link
                  className='social-icon-link'
                  to='/'
                  target='_blank'
                  aria-label='Instagram'
                >
                  <FaInstagram />
                </Link>
                <Link
                  className='social-icon-link'
                  to={
                    '//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber'
                  }
                  target='_blank'
                  aria-label='Youtube'
                >
                  <FaYoutube />
                </Link>
                <Link
                  className='social-icon-link'
                  to='/'
                  target='_blank'
                  aria-label='Twitter'
                >
                  <FaTwitter />
                </Link>
                <Link
                  className='social-icon-link'
                  to='/'
                  target='_blank'
                  aria-label='LinkedIn'
                >
                  <FaLinkedin />
                </Link>
              </div>
            </div>
          </section>
        </div>
    );
}

export default Footer;