import membershipNetImg from '../../../images/membershipNet.jpg'
import paymentImg from '../../../images/payment.jpg'

export const membership = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Membership',
  description: [
    "We operate strictly on a cost recovery basis, fees collected represent the fees we pay to the Badminton Playgroup.",
    "Payment has to be made in advance by the due date.",
    "Cancellation: An email will be sent to all players on the waiting list. The spot will be allocated on a first-come, first-serve basis.",
    "To keep game experience consistent, we only use Li-Ning G700 speed 77 shuttles.",
  ],
  imgStart: '',
  img: membershipNetImg,
  alt: ''
};

export const payment = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Payment',
    description: [
      "E-transfer: You can make your payment via e-transfer to membership@badmintonplaygroup.com.",
      "In-Person Payment. You can pay in cash.",
    ],
    imgStart: 'start',
    img: paymentImg,
    alt: ''
  };