import React, { useState }  from 'react'
import shuttleLogo from '../images/ShuttleLogo.jpg';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import { IconContext } from 'react-icons/lib';

const NavBar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
      <>
        <IconContext.Provider value={{ color: '#fff' }}>
          <div className="navbar">
            <div className='navbar-container container'>
              <Link to='/' className='navbar-logo'>
                  <img src={shuttleLogo} className="navbar-icon" alt="Badminton Playgroup" />
                  Badminton Playgroup
              </Link>
              <div className='tagline-text'>
                Where Exercise meets Fun, Competition & Comradery
              </div>
              <div className='menu-icon fa-bars' onClick={handleClick}>
                {click ? <FaTimes /> : <FaBars />}
              </div>
              <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                  <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                    Home
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/memberships' className='nav-links' onClick={closeMobileMenu}>
                    Membership
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/play-sessions' className='nav-links' onClick={closeMobileMenu}>
                    Play Sessions
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link to='/game-rules' className='nav-links' onClick={closeMobileMenu}>
                    Game Rules
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </IconContext.Provider>
      </>
  )
};

export default NavBar;
