import homePage from '../../../images/homepage.jpg'

export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Welcome to Badminton Playgroup!',
  description: [
    "Welcome to Badminton Playgroup, a non-profit group of accomplished players, including those who have played at the national and provincial levels, as well as advanced and high intermediate players.",
    "Our like-minded individuals gather for enjoyable workouts, Comradery, and spirited competitions on the badminton court. We value the insights of our members and actively seek feedback for continuous improvement of the playgroup. ",
    "This ensures not only a thrilling badminton experience but also an opportunity for our players to collectively enhance the playgroup. ",
    "Within our playgroup, you'll find a mix of advanced and high intermediate players, all driven to excel in every game they play in a spirited manner. Benefit from the expertise of our advanced players, who generously share tips and corrections, fostering an environment where everyone can enhance and elevate their game. ",
    "Come be a part of Badminton Playgroup, where a vibrant community of skilled players converges for fun, fitness, and the pursuit of badminton excellence. "+
    "Your journey to an enriched badminton experience starts right here!",
  ],
  imgStart: '',
  img: homePage,
  alt: ''
};